import React from 'react'
import AdminPanel from '../components/AdminPanel'
import Showcase from '../components/Showcase'
import {Helmet } from 'react-helmet-async'


export default function Home() {
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Karmen",
    "url": "https://karmen.in.ua",
  }
  return (
    <>
      <Helmet>
        <title>Кармен-оптовый магазин</title>
        <meta name='description' content='Оптово-розничный магазин по продаже чулочно-носочных изделий' />
        <script type='application/ld+json'>
        {JSON.stringify(jsonLdData)}
        </script>
      </Helmet>
      <AdminPanel />    
      <Showcase /> 
    </>
      
    
  )
}
