import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Home from "./pages/Home"
import { ProductsProvider } from "./context/ProductsContext"
import { AuthProvider } from "./context/AuthContext"
import Catalog from "./pages/Catalog"
import Map from "./pages/Map"
import Product from "./pages/Product"
import Login from "./pages/Login"
import AddCatalog from "./pages/AddCatalog"
import AddProduct from "./pages/AddProduct"
import EditInformation from "./pages/EditInformation"
import UsersList from "./pages/UsersList"
import OrderAdminList from "./pages/OrderAdminList"
import Order from "./pages/Order"
import Cart from "./pages/Cart"
import Checkout from "./pages/Checkout"
import OrderUserList from "./pages/OrderUserList"
import UserProfile from "./pages/UserProfile"
import NavLayout from "./components/NavLayout"
import EditGallery from "./pages/EditGallery"
import EditOptions from "./pages/EditOptions"
import EditProduct from "./pages/EditProduct"
import EditCatalog from "./pages/EditCatalog"
import ErrorRouter from "./pages/ErrorRouter"
import ErrorServer from "./pages/ErrorServer"
import Information from "./pages/Information"
import Contacts from "./pages/Contacts"
import EditBrands from "./pages/EditBrands"
import { BrandProvider } from "./context/BrandContext"
import { HelmetProvider } from "react-helmet-async"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorRouter />,
    children: [
      {
        path: "/",
        element: <NavLayout />,
        children: [
          {
            path: "/",
            element: <Home />,
          },

          {
            path: "/catalog/:id",
            element: <Catalog />,
          },
          {
            path: "/product/:id",
            element: <Product />,
          },
        ],
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/information",
        element: <Information />,
      },
      {
        path: "/contacts",
        element: <Contacts />,
      },
      {
        path: "/map",
        element: <Map />,
      },

      {
        path: "/login",
        element: <Login />,
      },

      {
        path: "/add_catalog",
        element: <AddCatalog />,
      },

      {
        path: "/edit_catalog/:id",
        element: <EditCatalog />,
      },
      {
        path: "/add_product",
        element: <AddProduct />,
      },
      {
        path: "/edit_product/:id",
        element: <EditProduct />,
      },
      {
        path: "/edit_options",
        element: <EditOptions />,
      },
      {
        path: "/edit_information",
        element: <EditInformation />,
      },

      {
        path: "/users_list",
        element: <UsersList />,
      },
      {
        path: "/order_admin_list",
        element: <OrderAdminList />,
      },
      {
        path: "/order_page/:id",
        element: <Order />,
      },

      {
        path: "/order_user_list/:id",
        element: <OrderUserList />,
      },
      {
        path: "/user_profile",
        element: <UserProfile />,
      },
      {
        path: "/edit_gallery",
        element: <EditGallery />,
      },
      {
        path: "/error_server",
        element: <ErrorServer />,
      },
      {
        path: "/edit_brands",
        element: <EditBrands />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <ProductsProvider>
          <BrandProvider>
            <RouterProvider router={router} />
          </BrandProvider>
        </ProductsProvider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
)
