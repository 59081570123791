import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'

export default function NavLayout() {
  return (
    <div className="grid grid-cols-1 grid-rows-[min-content,1fr] lg:grid-rows-[auto]  lg:grid-cols-[320px_minmax(0,1fr)] h-full gap-4 ">
      <Navbar />
      <div className="h-full " id="main_content">
        <Outlet />
      </div>
    </div>
  )
}
