import React, { useContext, useState } from "react"
import ProductsContext from "../../context/ProductsContext"
import { FaCaretDown } from "react-icons/fa"

export default function Select() {
  const { currencyShop, setCurrencyShop } = useContext(ProductsContext)
  const [showSelect, setShowSelect] = useState(false)

  const handleClick = (value) => {
    setCurrencyShop(value)
    setTimeout(() => setShowSelect(false), 500)
  }
  return (
    <div className="flex text-xl justify-end items-center gap-1 relative">
      <div className="hidden md:block">Цена в</div>
      <div
        className="text-sb text-xl hover:text-sb-dark  cursor-pointer flex 
        items-center "
        onClick={() => setShowSelect((prev) => !prev)}
      >
        <p>{currencyShop}</p>
        <FaCaretDown className="text-3xl" />
      </div>

      {showSelect ? (
        <ul
          className="absolute  top-full right-0 z-10 w-full border border-sb p-1
         rounded-lg bg-gray-100 "
        >
          <li
            className="text-center  hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer"
            onClick={() => handleClick("UAH")}
          >
            UAH
          </li>
          <li
            className="text-center hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer"
            onClick={() => handleClick("USD")}
          >
            USD
          </li>
          <li
            className="text-center hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer"
            onClick={() => handleClick("EUR")}
          >
            EUR
          </li>
        </ul>
      ) : null}
    </div>
  )
}
