import React, { useEffect, useState } from 'react'
import Links from '../components/Links'
import { useInformation } from '../swr'
import { ADDRESS_IMAGE, PHONE1, PHONE2 } from '../config'
import { Helmet } from "react-helmet-async"


export default function Contacts() {
 
  const information = useInformation()

   const [width, setWidth] = useState(0)
   const [height, setHeight] = useState(0)

  useEffect(() => {
     
     setWidth(document.getElementById("parent").offsetWidth)
     setHeight(document.getElementById("parent").offsetHeight)
   }, [])
  
 return (
   <>
     <Helmet>
       <title>Контакты магазина</title>
       <meta
         name="description"
         content="Адрес, телефоны, часы работы, положение на карте машазина Кармен"
       />
     </Helmet>
     <div className="w-full p-1">
       <Links home={true} back={true} />
       <div className="p-2 text-xl grid grid-cols-1 sm:grid-cols-[minmax(300px,1fr)_1fr] gap-5 justify-items-center">
         <div className="w-[300px] h-[400px] border border-gray-600 p-1 flex items-center justify-center">
           <img className="max-w-full max-h-full" src={ADDRESS_IMAGE} alt="" />
         </div>
         <div className="overflow-hidden justify-self-start ">
           <h2 className="font-bold ">Адрес магазина</h2>
           <p>{information ? information.address : null}</p>
           <h2 className="font-bold ">Телефоны</h2>
           <p>{PHONE1}</p>
           <p>{PHONE2}</p>
           <h2 className="font-bold">Режим работы</h2>
           <pre className="text-xl">
             {information ? information.workingHours : null}
           </pre>
         </div>
       </div>
       <div className=" p-2 border border-gray-500 rounded overflow-auto">
         <div className=" w-full h-[500px]" id="parent">
           <iframe
             title="karmen"
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.374422644064!2d36.30457775165689!3d50.00433697931523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a73fe250986b%3A0x435ec5d2c40c63da!2z0J7Qv9GC0L7QstGL0Lkg0LzQsNCz0LDQt9C40L0gItCa0LDRgNC80LXQvSI!5e0!3m2!1sru!2sua!4v1620472229174!5m2!1sru!2sua"
             width={width}
             height={height}
             stylename="border:0;"
             loading="lazy"
           ></iframe>
         </div>
       </div>
     </div>
   </>
 )
}
