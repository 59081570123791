import React from "react"
import PriceBlock from "./PriceBlock"

export default function PriceOptions({
  maxLevel,
  level,
  values,
  valuesObj,
  setValues,
   crumbs,
}) {
  
  return (
    <>
      {maxLevel === level ? (
        <PriceBlock
          values={values}
          valuesObj={valuesObj}
          setValues={setValues}
          crumbs={crumbs}
        />
      ) : (
        <div>
          {Object.keys(valuesObj).map((item) => (
            <div
              key={`${item}+${[...crumbs]}}`}
              style={{ paddingLeft: `${level * 40}px` }}
              className={
                level === maxLevel - 1 ? "flex justify-between option " : ""
              }
            >
              <div
                className={`${
                  level !== maxLevel - 1 ? "option" : ""
                } h-8 flex items-center text-xl  pl-5`}
              >
                <p>{item}</p>
              </div>
              <div>
                <PriceOptions
                  crumbs={[...crumbs, item]}
                  level={level + 1}
                  maxLevel={maxLevel}
                  values={values}
                  valuesObj={valuesObj[item]}
                  setValues={setValues}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
