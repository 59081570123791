import React from "react"
import { FaShareAlt,  FaTimes } from "react-icons/fa"
import { stringToPrice } from "../utils"

export default function PriceBlock({
  values,
  valuesObj,
  setValues,
  crumbs,
}) {

  const setValue = ({ value, name }) => {
    const copyValues=JSON.parse(JSON.stringify(values.optionValues))
    let valObj = copyValues
    crumbs.forEach(item=> {
      valObj = valObj[item]
    }
  )
    valObj[name] = value
    setValues({...values,optionValues:copyValues})
  }

  const changeHandler = (e) => {
    e.preventDefault()
    let { name, value } = e.target
      value =
        name === "price"
          ? value.replace(/[^\d.,]+/g, "").replace(",", ".")
        : value.replace(/[^\d]+/g, "")
    
    setValue({value,name})
  }

  const shareHandler = () => {
    const copyValues = JSON.parse(JSON.stringify(values.optionValues))
    const deep = (valObj) => {
      if ('price' in valObj) {
        valObj.price = valuesObj.price
        
      } else {
      Object.keys(valObj).forEach(item => {
          deep(valObj[item])
        })
      }
    }
    deep(copyValues)
    setValues({...values,optionValues:copyValues})
  }

  const shareResetHandler = () => {
     const copyValues = JSON.parse(JSON.stringify(values.optionValues))
     const deep = (valObj) => {
       if ("price" in valObj) {
         valObj.price = ''
       } else {
         Object.keys(valObj).forEach((item) => {
           deep(valObj[item])
         })
       }
     }
     deep(copyValues)
     setValues({ ...values, optionValues: copyValues })
  }

  const shareCloseHandler = () => {
    let arr = crumbs.slice(0, -1)
    const copyValues = JSON.parse(JSON.stringify(values.optionValues))
    let valObj=copyValues
    arr.forEach(item => {
      valObj=valObj[item]
    })
    Object.keys(valObj).forEach(item => {
      valObj[item].price=valuesObj.price
    })
setValues({...values,optionValues:copyValues})
    
  }
  const shareCloseResetHandler = () => {
       let arr = crumbs.slice(0, -1)
       const copyValues = JSON.parse(JSON.stringify(values.optionValues))
       let valObj = copyValues
       arr.forEach((item) => {
         valObj = valObj[item]
       })
       Object.keys(valObj).forEach((item) => {
         valObj[item].price = ''
       })
       setValues({ ...values, optionValues: copyValues })
  }

  const handleBlur = (e) => {
    let { value, name } = e.target
    value=stringToPrice(value)
    setValue({ value, name })
  }


  return (
    <div className="flex items-center text-black  h-8 px-2 gap-x-3">
      <div>
        {crumbs.length ? (
          <div className="flex items-center gap-5">
            <div className="flex items-center justify-center gap-3 border border-gray-400 p-1 rounded-md">
              <FaShareAlt
                className=" text-orange-400 cursor-pointer"
                title="Скопировать на все поля"
                onClick={shareHandler}
              />
              <FaTimes
                className="text-red-500 text-base cursor-pointer"
                title="Удалить со всех полей"
                onClick={shareResetHandler}
              />
            </div>
            <div className="flex items-center justify-center gap-3 border border-gray-400 p-1 rounded-md">
              <FaShareAlt
                className=" text-green-700 cursor-pointer"
                title="Скопировать в пределах опции"
                onClick={shareCloseHandler}
              />
              <FaTimes
                className="text-red-500 text-base cursor-pointer"
                title="Удалить в пределах опции"
                onClick={shareCloseResetHandler}
              />
            </div>
          </div>
        ) : null}
      </div>

      <input
        className="text-center text-lg/6 w-24"
        type="text"
        id="price"
        name="price"
        placeholder="Цена"
        value={valuesObj.price}
        onChange={changeHandler}
        onBlur={handleBlur}
      />
      <input
        className="text-center text-lg/6 w-40"
        type="text"
        id="barcode"
        name="barcode"
        maxLength={13}
        placeholder="Штрихкод"
        value={valuesObj.barcode}
        onChange={changeHandler}
      />
    </div>
  )
}
