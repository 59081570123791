import React, { useContext, useState } from 'react'
import  AuthContext  from '../context/AuthContext'
import { useSWRConfig } from 'swr'
import { useBrands } from '../swr'
import Links from '../components/Links'
import { FaImage, FaPencilAlt, FaSave, FaTimes, FaWindowClose } from 'react-icons/fa'
import Spinner from '../components/Spinner'
import { ToastContainer,toast } from 'react-toastify'
import HandleConfirm from '../components/HandleConfirm'
import AccessDenied from '../components/AccessDenied'
import { API_URL, NOIMAGE } from '../config'
import ModalDialog from '../components/ModalDialog'
import ModalImage from '../components/ModalImage'
import { saveImage } from '../handleImage'
import { Helmet } from "react-helmet-async"


export default function EditBrands() {
  const { user: { isAdmin, token } } = useContext(AuthContext)
  const { mutate } = useSWRConfig()
 
  const brandsList = useBrands()
  
   const initValues = {
     _id: null,
     name: "",
     pathSm:'',
     pathMd:''
   }
   const [values, setValues] = useState(initValues)
  const [showModal, setShowModal] = useState(false)
  const [brandForDelete, setBrandForDelete] = useState({})
  const [showImageUpload,setShowImageUpload]=useState(false)

  const handleDelete =async (item) => { 
     const res = await fetch(`${API_URL}/api/brands/${item._id}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    setValues(initValues)
    mutate(`${API_URL}/api/brands`)
  }

  const handleEdit = (item) => {
    setValues({_id:item._id,name:item.name,pathSm:item.imageSm,pathMd:item.imageMd})
   }
  const handleModal = (item) => {
    setBrandForDelete(item)
    setShowModal(true)
   }
  const handleSave = async () => {
    if (!values.name.trim()) {
      toast.error('Название бренда обязательно')
      return
    }
     const res = await fetch(`${API_URL}/api/brands`, {
      method: values._id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values),
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    setValues(initValues)

    mutate(`${API_URL}/api/brands`)
   }

  const handleName = (e) => {
    e.preventDefault()
    const { value } = e.target
    setValues({...values,name:value})
   }

  const resetBrand = () => {
    setValues(initValues)
  }

  const deleteImage = () => {
    setValues({...values,pathMd:'',pathSm:''})
  }

   const handleUploadChange = async (e) => {
     const file = e.target.files[0]
     const { pathMd, pathSm } = await saveImage({ file, token })   
     setValues({...values,pathMd,pathSm})
     setShowImageUpload(false)
   }

    const handle = (rez) => {
      if (rez) {
        handleDelete(brandForDelete)
      }
      setBrandForDelete({})
      setShowModal(false)
  }
  
 
  return (
    <>
      <Helmet>
        <title>Редактировать бренды</title>
        <meta
          name="description"
          content="Редактирование брендов в машазине Кармен"
        />
      </Helmet>
      {isAdmin ? (
        <div className="px-3">
          {brandsList ? (
            <>
              <div className="flex justify-between items-center text-3xl">
                <Links home={true} />

                <FaSave
                  className="text-sb cursor-pointer"
                  onClick={handleSave}
                />
              </div>
              <div className="py-3 grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="max-h-[60vh] overflow-auto border border-sb rounded-md p-1">
                  {brandsList.map((item, i) => (
                    <div
                      className="px-3 py-2 flex items-center gap-3 text-lg odd:bg-gray-200 even:bg-gray-50"
                      key={i}
                    >
                      <p className="flex-1"> {item.name}</p>
                      <FaPencilAlt
                        className="text-sb cursor-pointer text-xl"
                        onClick={() => handleEdit(item)}
                      />
                      <FaTimes
                        className="text-red-500 cursor-pointer text-xl"
                        onClick={() => handleModal(item)}
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <div className=" flex items-center justify-between gap-x-[10px] mb-2">
                    <input
                      type="text"
                      className="my-1 h-8 text-xl px-1 flex-1"
                      placeholder="Название бренда"
                      value={values.name}
                      onChange={handleName}
                    />
                    <FaTimes
                      className="text-3xl text-red-500 hover:cursor-pointer"
                      title="Новые опции"
                      onClick={resetBrand}
                    />
                  </div>

                  <div className="flex items-center justify-center h-full">
                    <div>
                      <div className="w-[300px] h-[400px] flex justify-center items-center border border-gray-700 p-1">
                        <img
                          className="max-w-full max-h-full"
                          src={
                            values.pathMd
                              ? `${API_URL}${values.pathMd}`
                              : NOIMAGE
                          }
                          alt=""
                        />
                      </div>

                      <div className="flex text-3xl justify-around w-full my-2">
                        <FaImage
                          className="flex cursor-pointer  text-sb  "
                          onClick={() => {
                            setShowImageUpload(true)
                          }}
                          name="save"
                          title="Сохранить"
                        />
                        <FaWindowClose
                          className="flex cursor-pointer text-red-500"
                          onClick={deleteImage}
                          name="delete"
                          title="Удалить"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}
          <ToastContainer />
          {showModal ? (
            <HandleConfirm
              title1="Удалить бренд?"
              title2={brandForDelete.name}
              handle={handle}
            />
          ) : null}
          {showImageUpload ? (
            <ModalDialog>
              <ModalImage
                setShowImageUpload={setShowImageUpload}
                handleUploadChange={handleUploadChange}
              />
            </ModalDialog>
          ) : null}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  )
}
