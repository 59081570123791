import React, { useEffect } from "react"
import ProductPriceOptions from "./ProductPriceOptions"


export default function ProductTable({ product, setValues, values }) {
  useEffect(() => {
    // делаем таблицу полосатой
    const elements = document.querySelectorAll(".option")
    elements.forEach((elem, key) => {
      if (key % 2) {
        elem.style.backgroundColor = "#F4F6F6"
      } else {
        elem.style.backgroundColor = "#E5E8E8"
      }
    })
  }, [values])

  const crumbs = []
  const level = 0
  const maxLevel = Object.keys(product.ownOptions).filter(
    (item) => product.ownOptions[item].length
  ).length

  

  return (
    <div className="text-base max-h-[55vh] overflow-auto">
      <ProductPriceOptions crumbs={crumbs} level={level} maxLevel={maxLevel} product={product} values={values} valuesObj={values} setValues={setValues}/>
    </div>
  )
}

// values это product.optionValues, valuesObj это спускаемое по дереву values, которое вначале равно values, в конце {price:'',barcode:'',qnt:''}
