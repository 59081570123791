import { useNavigate } from "react-router-dom"


import { FaArrowLeft, FaHome } from "react-icons/fa"

export default function Links({ home, back }) {
  const navigate = useNavigate()
  return (
    <div className="flex items-center text-sb  gap-3 h-9">
      {back ? (
        // <FaChevronCircleLeft
        <FaArrowLeft
          onClick={() => navigate(-1)}
          title="Назад"
          className="hover:text-sb-dark hover:cursor-pointer text-2xl"
        />
      ) : null}
      {home ? (
        <FaHome
          onClick={() => navigate("/")}
          title="На главную"
          className="hover:text-sb-dark hover:cursor-pointer text-3xl"
        />
      ) : null}
    </div>
  )
}
