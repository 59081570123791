import React, { useEffect } from "react"
import PriceOptions from "./PriceOptions"

export default function PriceTable({ setValues, values }) {
  useEffect(() => {
    // делаем таблицу полосатой

    const elements = document.querySelectorAll(".option")
    elements.forEach((elem, key) => {
      if (key % 2) {
        elem.style.backgroundColor = "#F4F6F6"
      } else {
        elem.style.backgroundColor = "#E5E8E8"
      }
    })
  }, [values])

  const crumbs = []
  const level = 0
  const maxLevel = Object.keys(values.ownOptions).filter(
    (item) => values.ownOptions[item].length
  ).length

  
  return (
    <div className="text-base max-h-[55vh] overflow-auto">
      <PriceOptions
        crumbs={crumbs}
        level={level}
        maxLevel={maxLevel}
        values={values}
        valuesObj={values.optionValues}
        setValues={setValues}
      />
    </div>
  )
}

// values это product.optionValues, valuesObj это спускаемое по дереву values, которое вначале равно values, в конце {price:'',barcode:'',qnt:''}
