import React from 'react'

export default function Pagination({ productsPerPage, qntProducts,currentPage,setCurrentPage }) {

  const numbers = []
  for (let i = 1; i <= Math.ceil(qntProducts / productsPerPage); i++){
    numbers.push(i)
  }
  
  return (
    <>
     {numbers.length>1 ? (
     <div className='py-1 flex justify-center items-center gap-2 flex-wrap'>
      {numbers.map(item => (
        <div key={item._id} className={`text-2xl  flex justify-center items-center
         w-10 h-10 border border-blue-500 rounded-full  ${currentPage === item ?
            'bg-sb text-gray-100' : 'bg-inherit text-inherit cursor-pointer hover:bg-gray-50'}`} onClick={()=>setCurrentPage(item)}>
         <p>{item}</p> 
        </div>
      ))}
    </div>
    ):null}
    </>
   
   
  )
}
