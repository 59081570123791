
import { API_URL, NOIMAGE } from "../config/index"
import {
  getCurrencySymbol,
  getPriceForShow,
  getShortDescription,
} from "../utils/index"
import { useContext, useEffect, useState } from "react"
import ProductsContext from "../context/ProductsContext"

import Loupe from "./Loupe"
import { useNavigate } from "react-router-dom"
import { useCurrencyRate } from "../swr"


export default function ProductsList({ products = [], isShowAsList }) {
  const [innerWidth, setInnerWidth] = useState(0)
  const navigate = useNavigate()
  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])

 const currencyRate = useCurrencyRate()

  const { currencyShop } = useContext(ProductsContext)
  const [isShow, setIsShow] = useState(false)
  const [image, setImage] = useState("")

  
  

  return (
    <>
      {isShowAsList ? (
        <div className="px-1">
          <table className="w-full border-collapse table-fixed text-base border border-gray-500">
            <thead className="border border-gray-500">
              <tr>
                <th className="w-[70px]">&nbsp;</th>
                <th className="w-[180px]">Название</th>

                <th className="w-auto hidden lg:table-cell">Описание</th>
                <th className="w-[100px]">Цена</th>
              </tr>
            </thead>
            <tbody className="border border-gray-500">
              {products.map((item, i) => (
                <tr
                  className="border border-gray-500 hover:border-x-blue-500 hover:border-x
                     hover:cursor-pointer hover:bg-gray-50"
                  onClick={() => navigate(`/product/${item._id}`)}
                  key={i}
                >
                  <td className=" text-center align-middle leading-4 border border-gray-500 ">
                    <div className=" inline-flex justify-center items-center p-0.5
                         h-[85px] w-[65px]">
                      <img
                        className="max-w-full max-h-full cursor-zoom-in active:scale-95"
                        src={
                          item.imagesSm.length
                            ? `${API_URL}${item.imagesSm[0]}`
                            : `${NOIMAGE}`
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsShow(true)
                          setImage(item.images[0])
                        }}
                      />
                       
                    </div>
                  </td>
                  <td className="text-center border border-gray-500 px-1">
                    <h3>{item.name}</h3>
                  </td>

                  <td className="text-center border border-gray-500 px-1 hidden lg:table-cell">
                    {getShortDescription(item.description, innerWidth / 15)}
                  </td>
                  <td className="text-center text-xl border border-gray-500 px-1">
                    {currencyRate ? (
                      <div>
                        {getPriceForShow({
                          currencyRate,
                          currencyShop,
                          product: item,
                        })}{" "}
                        {getCurrencySymbol(currencyShop)}
                      </div>
                    ) : (
                      <div> </div>
                    )}
                  </td>
                </tr>
              ))}
                
            </tbody>
          </table>
          {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
        </div>
      ) : (
        <div className="grid gap-5 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] justify-items-center">
          {products.map((item, i) => (
            <div
              className="flex flex-col gap-1 pt-1 items-center border border-gray-500 shadow-md w-[300px] h-[470px]
               overflow-auto hover:cursor-pointer hover:border-blue-500"
              onClick={() => navigate(`/product/${item._id}`)}
              key={i}
            >
              <div className="w-[270px] h-[360px] border border-gray-500 p-1 flex items-center justify-center">
                <img
                  className="max-w-full max-h-full hover:cursor-zoom-in active:scale-95"
                  src={
                    item.imagesMd.length
                      ? `${API_URL}${item.imagesMd[0]}`
                      : `${NOIMAGE}`
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsShow(true)
                    setImage(item.images[0])
                  }}
                />
              </div>
              <h3 className="flex w-full items-center justify-center flex-1 text-lg/5 px-1">
                {item.name}
              </h3>

              <div className="h-[50px] w-full flex justify-center items-center bg-gray-200 text-2xl border border-t-gray-500">
                {currencyRate ? (
                  <p>
                    {getPriceForShow({
                      currencyRate,
                      currencyShop,
                      product: item,
                    })}{" "}
                    {getCurrencySymbol(currencyShop)}
                  </p>
                ) : (
                  <p> </p>
                )}
              </div>
            </div>
          ))}
           
          {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
        </div>
      )}
      
    </>
  )
}
