import AccessDenied from "../components/AccessDenied"
import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { API_URL } from "../config"
import { useInformation } from "../swr"
import { FaSave } from "react-icons/fa"
import { Helmet } from "react-helmet-async"

export default function EditInformation() {
  const {
    user: { isAdmin, token },
  } = useContext(AuthContext)
 
  const navigate = useNavigate()

 
  const information = useInformation()
  const [values, setValues] = useState({})

  useEffect(() => {
    if (!information) return
    
    setValues({ ...information })
  }, [information])

  const changeHandler = (e) => {
    e.preventDefault()
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const submitHandler = async () => {
    const res = await fetch(`${API_URL}/api/information`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values),
    })
    const data = res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    navigate("/")
  }

  return (
    <>
      <Helmet>
        <title>Редактировать страницу информации</title>
        <meta
          name="description"
          content="Редактирование страницы информации в машазине Кармен"
        />
      </Helmet> 
      <ToastContainer />
      {isAdmin ? (
        <div className="px-3">
          <div className="flex justify-between items-center">
            <Links home={true} back={true} />
            <div
              className="text-3xl text-sb cursor-pointer"
              onClick={submitHandler}
            >
              <FaSave />
            </div>
          </div>

          <h2 className="font-bold">Контактная информация</h2>

          <label className="block text-2xl" htmlFor="aboutUs">
            О нас
          </label>
          <textarea
            className="p-1 w-full min-h-[100px] text-lg"
            name="aboutUs"
            id="aboutUs"
            onChange={changeHandler}
            value={values.aboutUs}
          ></textarea>
          <label className="block text-2xl" htmlFor="conditions">
            Условия сотрудничества
          </label>
          <textarea
            className="p-1 w-full min-h-[100px] text-lg"
            id="conditions"
            name="conditions"
            onChange={changeHandler}
            value={values.conditions}
          ></textarea>
          <label className="block text-2xl" htmlFor="productReturn">
            Возврат товара
          </label>
          <textarea
            className="p-1 w-full min-h-[100px] text-lg"
            id="productReturn"
            name="productReturn"
            onChange={changeHandler}
            value={values.productReturn}
          ></textarea>
          <label className="block text-2xl" htmlFor="delivery">
            Доставка
          </label>
          <textarea
            className="p-1 w-full min-h-[100px] text-lg"
            id="delivery"
            name="delivery"
            onChange={changeHandler}
            value={values.delivery}
          ></textarea>
          <label className="block text-2xl" htmlFor="address">
            Адрес
          </label>
          <textarea
            className="p-1 w-full min-h-[100px] text-lg"
            id="address"
            name="address"
            onChange={changeHandler}
            value={values.address}
          ></textarea>
          <label className="block text-2xl" htmlFor="workingHours">
            Часы работы
          </label>
          <textarea
            className="p-1 w-full min-h-[100px] text-lg"
            id="workingHours"
            name="workingHours"
            onChange={changeHandler}
            value={values.workingHours}
          ></textarea>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  )
}
