import { createContext, useEffect, useState } from "react"
import { API_URL } from "../config"

const ProductsContext = createContext()

export const ProductsProvider = ({ children }) => {
  const [currencyShop, setCurrencyShop] = useState("UAH")
  
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart"))||[])


 
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart))
  }, [cart])
 

  return (
    <ProductsContext.Provider
      value={{
        currencyShop,
        setCurrencyShop,       
        cart,
        setCart,       
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

export default ProductsContext
