import { useEffect, useState } from "react"
import { useCatalogs } from "../swr"

export function getCurrencySymbol(currencyShop) {
  switch (currencyShop) {
    case "UAH":
      return "₴"

    case "EUR":
      return "€"

    case "USD":
      return "$"

    default:
      return "₴"
  }
}

export const idToString = (id) => {
  return id === null ? "" : id.toString()
}

export const isNotEmpty = (obj) => {
  return !!Object.keys(obj).length
}

export function getPriceForShow({ currencyShop, currencyRate, product }) {
  const { min, max } = getStringPrice(product)
  const showMin = (
    (currencyRate[product.currencyValue] * +min) /
    currencyRate[currencyShop]
  ).toFixed(2)
  const showMax = (
    (currencyRate[product.currencyValue] * +max) /
    currencyRate[currencyShop]
  ).toFixed(2)
  const string = min === max ? showMin : `${showMin} - ${showMax}`
  return string
}

export const getCatTree = (cat, catArray) => {
  var result = [cat.name]
  const findParent = (cat) => {
    const parent = catArray.find((elem) => elem._id === cat.parentId)
    if (parent) {
      result.push(parent.name)
      findParent(parent)
    }
    return
  }
  findParent(cat)

  return result.reverse().join(" ➔ ")
}

export const getArrayCatTree = (cat, catArray) => {
  var result = [cat]
  const findParent = (cat) => {
    const parent = catArray.find((elem) => elem._id === cat.parentId)
    if (parent) {
      result.push(parent)
      findParent(parent)
    }
    return
  }
  findParent(cat)

  return result.reverse()
}

export const useCrumbsArray = (product) => {
  const [arr, setArr] = useState([])
  const catalogs = useCatalogs()
  useEffect(() => {
    if (product && catalogs) {
      const catalog = catalogs.find(
        (item) => idToString(item._id) === idToString(product.catalogId)
      )
      let result = [catalog]
      const findParent = (catalog) => {
        const parent = catalogs.find(
          (item) => idToString(item._id) === idToString(catalog.parentId)
        )
        if (parent) {
          result.push(parent)
          findParent(parent)
        }
        return
      }
      findParent(catalog)
      setArr(result.reverse())
    }
  }, [product, catalogs])

  return arr
}

export const getShortDescription = (description, length) => {
  return description.length > length
    ? `${description.slice(0, length)}...`
    : description
}

export const stringToPrice = (string) => {
  const priceNum =
    parseFloat(string.replace(/[^\d.,]+/g, "").replace(",", ".")) || 0

  return priceNum === 0 ? "" : priceNum.toFixed(2)
}

export const getQntInCart = (cart) =>
  cart.reduce((acc, item) => acc + +item.qnt, 0)
export const getTotalInCart = (cart) =>
  cart.reduce(
    (acc, item) => ({
      ...acc,
      [item.currencyValue]: acc[item.currencyValue] + item.qnt * item.price,
    }),
    {
      UAH: 0,
      USD: 0,
      EUR: 0,
    }
  )

export const getTotalAmount = (cart) => {
  const totalObj = getTotalInCart(cart)
  let strArr = []
  for (let key in totalObj) {
    if (totalObj[key]) {
      strArr.push(`${totalObj[key].toFixed(2)}${getCurrencySymbol(key)}`)
    }
  }
  return strArr.join(" + ") || "0"
}
// Функция возвращает отсортированный list for drop_down_menu формата {cat:category,tree:string tree}

export const getListForCatalogsMenu = (catArray) => {
  const list = catArray
    .map((item) => ({ cat: item, tree: getCatTree(item, catArray) }))
    .sort((a, b) => (a.tree.toLowerCase() > b.tree.toLowerCase() ? 1 : -1))
  return list
}

export const formatingPhone = (number) => {
  const digit = number.replace(/[^\d]/g, "")
  const numberLength = digit.length
  let rez = ""
  if (!numberLength) {
    rez = digit
  } else {
    if (numberLength < 4) {
      rez = digit.slice(0, 3)
    } else {
      if (numberLength < 7) {
        rez = `(${digit.slice(0, 3)}) ${digit.slice(3, 6)}`
      } else {
        if (numberLength < 9) {
          rez = `(${digit.slice(0, 3)}) ${digit.slice(3, 6)}-${digit.slice(
            6,
            8
          )}`
        } else {
          if (numberLength >= 9) {
            rez = `(${digit.slice(0, 3)}) ${digit.slice(3, 6)}-${digit.slice(
              6,
              8
            )}-${digit.slice(8, 10)}`
          }
        }
      }
    }
  }
  return rez
}

export const formatingDate = (value) => {
  const date = new Date(value)
  const day = date.getDate().toString().padStart(2, "0")
  const month = date.getMonth().toString().padStart(2, "0")
  const year = date.getFullYear().toString().padStart(2, "0")
  return `${day}-${month}-${year}`
}

export const createPriceObject = ({ ownOptions, optionValues }) => {
  // убираем поля пустышки
  const fillingOwnOptions = Object.assign(
    {},
    ...Object.keys(ownOptions)
      .filter((item) => ownOptions[item].length)
      .map((item) => ({ [item]: ownOptions[item].sort() }))
  )

  let rez = { price: "", barcode: "", qnt: "" }

  Object.keys(fillingOwnOptions)
    .reverse()
    .forEach((option) => {
      rez = Object.assign(
        {},
        ...fillingOwnOptions[option].map((value) => ({
          [value]: JSON.parse(JSON.stringify(rez)),
        }))
      )
    })

  const deep = (newOptions, oldOptions) => {
    if (
      newOptions.hasOwnProperty("price") &&
      oldOptions.hasOwnProperty("price")
    ) {
      newOptions.price = oldOptions.price
      newOptions.barcode = oldOptions.barcode
    } else {
      Object.keys(newOptions).forEach((item) => {
        if (!oldOptions.hasOwnProperty(item)) return

        deep(newOptions[item], oldOptions[item])
      })
    }
    return newOptions
  }

  return deep(rez, optionValues)
}

export const getStringPrice = (values) => {
  const pricesArray = []
  const deep = (options) => {
    if (options.hasOwnProperty("price")) {
      if (options.price) {
        pricesArray.push(options.price)
      }
      return
    } else {
      Object.keys(options).forEach((item) => {
        deep(options[item])
      })
    }
  }

  deep(values.optionValues)

  const min = pricesArray.sort((a, b) => a - b)[0] || ""
  const max = pricesArray.sort((a, b) => b - a)[0] || ""

  let string =
    min === max
      ? `${min.toString()} ${getCurrencySymbol(values.currencyValue)}`
      : `${min}...${max} ${getCurrencySymbol(values.currencyValue)}`

  return { string, min, max }
}
