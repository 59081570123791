import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useBrands, useFilteredCatalogs } from "../swr"
import WaveContainer from "./WaveContainer"
import {
  ABOUT_IMAGES,
  ADDRESS_IMAGE,
  API_URL,
  LOGO_KS,
  LOGO_VF,
  NOIMAGE,
  PHONE1,
  PHONE2,
  PHONE3,
} from "../config"
import BrandContext from "../context/BrandContext"
import { FaCircle, FaFilter, FaThLarge, FaTimes } from "react-icons/fa"
import { isNotEmpty } from "../utils"

export default function Navbar() {
  const { brand, setBrand } = useContext(BrandContext)

  const [showCatalogs, setShowCatalogs] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleBrandClick = (item) => {
    setBrand(item)
    // if (windowWidth < 640) {
    setTimeout(() => setShowFilter(false), 500)
    // }
  }

  const resetBrand = () => {
    setBrand({})

    setTimeout(() => setShowFilter(false), 500)
  }

  const handleCatalogClick = (id) => {
    navigate(`/catalog/${id}`)
    if (windowWidth < 1024) {
      setTimeout(() => setShowCatalogs(false), 500)
    }
  }

  const navigate = useNavigate()

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const brandsList = useBrands()
  const catalogs = useFilteredCatalogs(brand._id)
  const catalogsList = catalogs
    ? catalogs.filter((item) => item.parentId === null)
    : []

  return (
    <div
      className={`w-full lg:h-full  flex flex-col gap-2 relative border border-gray-300  rounded-md`}
    >
      <div className="flex justify-between items-center p-2">
        <div
          className="text-lg text-gray-50 px-5 py-2 border rounded-lg bg-sb
        cursor-pointer hover:bg-sb-dark"
        >
          <div
            className="flex items-center gap-4"
            onClick={() => setShowCatalogs((prev) => !prev)}
          >
            <FaThLarge />
            <h2>Каталоги</h2>
          </div>
        </div>
        <div
          className="text-lg text-gray-50 p-3 rounded-lg  bg-sb
        cursor-pointer hover:bg-sb-dark"
          onClick={() => setShowFilter((prev) => !prev)}
        >
          <FaFilter />
          {isNotEmpty(brand) ? (
            <FaCircle className="text-red-500 text-[12px] absolute top-[6px] right-[6px]" />
          ) : null}
        </div>
      </div>
      <div className="hidden lg:block w-full h-full   border border-t-gray-300 rounded-md">
        <div className="p-2 flex flex-col gap-4 ">
          <img
            className="max-w-full border border-gray-500 p-1 rounded-md"
            src={ADDRESS_IMAGE}
            alt="магазин"
          />

          <div className="text-xl/8 p-2 border border-gray-500 rounded-md text-amber-800">
            <h4 className="indent-4">Xарьков, ТЦ Барабашово, стоянка "Болото", BK-512</h4>
          </div>
          <div className="text-2xl p-2 border border-gray-500 rounded-md text-amber-800">
            <div className="flex items-center gap-3 p-1">
              <img src={LOGO_VF} className="max-w-6" alt="" />
              <p>{PHONE1}</p>
            </div>

            <div className="flex items-center gap-3 p-1">
              <img src={LOGO_KS} className="max-w-6" alt="" />
              <p>{PHONE2}</p>
            </div>

            <div className="flex items-center gap-3 p-1">
              <img src={LOGO_VF} className="max-w-6" alt="" />
              <p>{PHONE3}</p>
            </div>
          </div>
        </div>
      </div>

      {showFilter && brandsList && (
        <div
          className={` w-full h-[700px]  top-[70px] left-0 bg-gray-50  absolute  z-40 lg:h-auto lg:bottom-0
         flex flex-col gap-2 items-center  border border-sb rounded-md p-3  overflow-auto `}
        >
          <div className="w-full flex py-2 items-center justify-between gap-2">
            <p className="text-xl font-bold text-[#78350F]">Бренд:</p>
            <h4 className="text-xl">{isNotEmpty(brand) ? brand.name : ""}</h4>
            <div
              className="text-2xl text-red-500 cursor-pointer"
              onClick={resetBrand}
            >
              <FaTimes />
            </div>
          </div>

          {brandsList.map((item, i) => (
            <div className="w-full  hover:cursor-pointer" key={i}>
              <WaveContainer>
                <div
                  className="flex justify-start items-center gap-1 text-gray-800  border border-blue-600 
                      flex-nowrap  p-2 rounded-md shadow-md shadow-blue-600/50"
                  // onClick={(e) => handleCategoryClick(item._id)}
                  onClick={(e) => handleBrandClick(item)}
                >
                  <div className="w-[40px] h-[30px]  flex items-center justify-center">
                    <img
                      className="max-w-full max-h-full"
                      src={item.imageSm ? `${API_URL}${item.imageSm}` : NOIMAGE}
                      alt=""
                    />
                  </div>
                  <p className="text-nowrap text-center flex-1 text-xl ">
                    {item.name}
                  </p>
                </div>
              </WaveContainer>
            </div>
          ))}
        </div>
      )}
      {showCatalogs && catalogsList ? (
        <div
          className=" w-full h-[700px]  top-[70px] left-0 bg-gray-50  absolute  z-30 lg:h-auto lg:bottom-0
         flex flex-col gap-2 items-center  border border-sb rounded-md p-3  overflow-auto "
        >
          {catalogsList.map((item, i) => (
            <div className="w-full  hover:cursor-pointer" key={i}>
              <WaveContainer>
                <div
                  className="flex justify-start items-center gap-1 text-gray-800  border border-blue-600 
                      flex-nowrap  p-2 rounded-md shadow-md shadow-blue-600/50"
                  onClick={(e) => handleCatalogClick(item._id)}
                >
                  <div className="w-[40px] h-[30px]  flex items-center justify-center">
                    <img
                      className="max-w-full max-h-full"
                      src={item.imageSm ? `${API_URL}${item.imageSm}` : NOIMAGE}
                      alt=""
                    />
                  </div>
                  <h4 className="text-nowrap text-center flex-1 text-xl">
                    {item.name}
                  </h4>
                </div>
              </WaveContainer>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
