import { createContext, useState, useEffect } from "react"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [isUpload, setIsUpload] = useState(false)
  // isUpload отключает второй useEffect при первой отрисовке
 useEffect(() => {
   setUser(JSON.parse(localStorage.getItem("user")) || {})
   setIsUpload(true)
 }, [])

 
  useEffect(() => {  
    if (isUpload) {
      localStorage.setItem("user", JSON.stringify(user))
    }    
  }, [user,isUpload])
 
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
