import React from "react"
import { FaExclamationTriangle } from "react-icons/fa"
import { Link, useSearchParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

export default function ErrorServer() {
  const [searchParams] = useSearchParams()
  const message = searchParams.get("message")
  return (
    <>
      <Helmet>
        <title>Ошибка сервера</title>
        <meta name="description" content="Ошибка на сторонк сервера" />
      </Helmet>
      <div className="flex flex-col items-center justify-center h-full gap-2 text-xl">
        <FaExclamationTriangle className="text-7xl text-red-500" />
        <p>{message}</p>
        <Link className="text-blue-500 cursor-pointer" to="/">
          Вернуться на главную
        </Link>
      </div>
    </>
  )
}
