import React from 'react'
import ProductPriceBlock from './ProductPriceBlock'


export default function ProductPriceOptions({ maxLevel, level, values,valuesObj, setValues, product, crumbs }) {

  return (
    <>
      {maxLevel === level ? (
        <ProductPriceBlock product={product} values={values} valuesObj={valuesObj} setValues={setValues} crumbs={crumbs} />
      ) : (
        <div>
          {Object.keys(valuesObj).map((item) => (
            <div
              key={`${item}+${[...crumbs]}}`}
              style={{ paddingLeft: `${level * 10}px` }}
              className={
                level === maxLevel - 1 ? "flex justify-between option " : ""
              }
            >             
              <div className={`${level !== maxLevel - 1 ? "option" : ""} h-8 flex items-center text-base md:text-lg  pl-1`}>
                <p>{item}</p> 
               
              </div>
              <div>
                <ProductPriceOptions
                  crumbs={[...crumbs, item]}
                  level={level + 1}
                  maxLevel={maxLevel}
                  product={product}
                  values={values}
                  valuesObj={valuesObj[item]}
                  setValues={setValues}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
