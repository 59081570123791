import React from "react"
import Header from "./Header"
import Footer from "./Footer"

export default function Layout({ children }) {
  return (
    <div
      className="font-Robotto w-full min-h-[100vh] grid grid-cols-1
     grid-rows-[150px_minmax(650px,1fr)_100px] gap-y-3"
    >
      <Header />
     
      <div
        className=" w-full max-w-[1800px] justify-self-center 
        border border-gray-300 rounded-md p-2"
        id="layout_container"
      >        
        {children}
      </div>
      <Footer />
    </div>
  )
}
