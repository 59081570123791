import React from 'react'
import { useInformation } from '../swr'
import { ABOUT_IMAGES } from '../config'
import { Helmet } from "react-helmet-async"

export default function Information() {
  
  const information = useInformation()
 
  return (
    <>
      <Helmet>
        <title>Информайия</title>
        <meta name="description" content="Информация о магазине Кармен" />
      </Helmet>
      <div className="text-xl">
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          О магазине
        </h2>
        <p className="">{information ? information.aboutUs : null}</p>
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          Сотрудничество{" "}
        </h2>
        <p className="">{information ? information.conditions : null}</p>
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          Возврат товара
        </h2>
        <p className="">{information ? information.productReturn : null}</p>
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          Доставка
        </h2>
        <p className="">{information ? information.delivery : null}</p>
        <div
          className="my-3 grid justify-items-center 
        grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-1
        sm:grid-cols-[repeat(auto-fill,minmax(210px,1fr))] sm:gap-3
        lg:grid-cols-[repeat(auto-fill,minmax(270px,1fr))] md:gap-5
      "
        >
          {ABOUT_IMAGES.map((image, i) => (
            <div
              className="w-[170px] h-227px] p-1 border border-gray-700
            sm:w-[210px] h-280px]
            lg:w-[270px] h-360px]"
              key={i}
            >
              <img className="max-w-full max-h-full" src={image} alt="" />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
