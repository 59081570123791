import { v4 as uuid } from "uuid"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { io } from "socket.io-client"
import { useNavigate } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { API_URL, TELEGRAM_BOT_URI, VIBER_BOT_URI } from "../config"
import { Link } from "react-router-dom"
import AuthContext from "../context/AuthContext"
import { Helmet } from "react-helmet-async"
import { FaTelegram, FaTimes, FaViber } from "react-icons/fa"

export default function Login() {
  const [authKey, setAuthKey] = useState(uuid().replace(/-/gi, ""))
  const [authMethod, setAuthMethod] = useState("")
  const { setUser } = useContext(AuthContext)
  const [arg, setArg] = useState()

  const navigate = useNavigate()
  useEffect(() => {
    const socket = io(API_URL, { transports: ["websocket"] })
    socket.on("authkey", (arg) => {
      
      setArg(arg)
    })
    return () => {
      socket.disconnect()
    }
  }, [])
 

  useEffect(() => {
    const login = async () => {     
      const res = await fetch(`${API_URL}/api/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ authKey, authMethod }),
      })

      const user = await res.json()

      if (!res.ok) {
        toast.error(user.message)
        return
      }

      setUser(user)
      navigate(-1)
    }
    // если то что пришло с сервера через socketio совпадает с ключем который отправил браузер по deepLink
    // тогда делаем логин и получаем обратно user с токеном
    if (arg === authKey) {
      login()
    }
  }, [arg])

  return (
    <>
      <Helmet>
        <title>Авторизация</title>
        <meta name="description" content="Авторизация в магазине Кармен" />
      </Helmet>
      <div className="flex justify-center items-center w-full h-full">
        <div
          className="relative px-6 py-4 max-w-full w-[400px] border
         border-gray-500 bg-gray-50 shadow-md shadow-gray-400/50"
        >
          <ToastContainer />
          <div
            className="absolute text-3xl top-3 right-3 text-red-500 cursor-pointer"
            onClick={() => navigate(-1)}
            title="Закрыть"
          >
            <FaTimes />
          </div>
          <h1 className="font-bold text-center">Авторизация</h1>
          <div className="py-5 flex justify-between items-center text-7xl">
            <Link
              to={`viber://pa?chatURI=${VIBER_BOT_URI}&context=${authKey}`}
              target="_blank"
            >
              <FaViber
                className="text-[#7360f2] cursor-pointer active:scale-[0.98]"
                title="Авторизация через Viber"
                onClick={() => setAuthMethod("Viber")}
                name="viber"
              />
            </Link>
            <Link
              to={`https://t.me/${TELEGRAM_BOT_URI}?start=${authKey}`}
              target="_blank"
            >
              <FaTelegram
                className="text-[#2aabee] cursor-pointer active:scale-95"
                title="Авторизация через Telegram"
                onClick={() => setAuthMethod("Telegram")}
                name="telegram"
              />
            </Link>
          </div>

          <div className="flex justify-center items-center">
            <p className="text-center text-base bg-amber-100 p-2  shadow-md shadow-gray-700/50">
              Для авторизации необходимо наличие аккаунта в Viber или Telegram
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
