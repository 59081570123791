import React from "react"
import { getCurrencySymbol } from "../utils/index"
import { FaMinus, FaPlus } from "react-icons/fa"

export default function TestProductBlock({
  values,
  valuesObj,
  setValues,
  product,
  crumbs,
}) {
  const price = valuesObj.price
    ? valuesObj.price + " " + getCurrencySymbol(product.currencyValue)
    : ""
  const qnt = valuesObj.qnt

  const setValue = (value) => {
    const copyValues = JSON.parse(JSON.stringify(values))
    let valObj = copyValues
    crumbs.forEach((item) => {
      valObj = valObj[item]
    })
    valObj.qnt = value
    setValues(copyValues)
  }

  const handleChange = (e) => {
    let { value } = e.target
    value = value.replace(/[^\d]+/g, "")
    e.preventDefault()
    setValue(value)
  }
  const inc = () => {
    let value = qnt
    value = +value.replace(/[^\d]+/g, "")
    value = value >= 999 ? value.toString() : (value + 1).toString()
    setValue(value)
  }

  const dec = () => {
    let value = qnt
    value = +value.replace(/[^\d]+/g, "")
    value = value <= 1 ? "" : (value - 1).toString()
    setValue(value)
  }

  return (
    <div className="p-1 gap-x-3 flex items-center justify-end text-lg">
      <div className="flex items-center gap-x-1 ">
        <FaMinus
          className="text-red-500 text-base cursor-pointer"
          onClick={dec}
        />
        <input
          className="max-w-10 text-center text-base tracking-widest"
          type="text"
          value={qnt}
          onChange={handleChange}
          maxLength={3}
        />

        <FaPlus
          className="text-green-700 text-base cursor-pointer"
          onClick={inc}
        />
      </div>
      <div className="flex justify-end min-w-[65px] select-none">{price}</div>
    </div>
  )
}
